//
// Scaffolding
// --------------------------------------------------


// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
	.box-sizing(border-box);
}
*:before,
*:after {
	.box-sizing(border-box);
}


// Body reset

html {
	font-size: 62.5%;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
	font-family: @font-family-base;
	font-size: @font-size-base;
	line-height: @line-height-base;
	color: @text-color;
	background-color: @body-bg;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	resize: none;
}


// Links

a {
	color: @link-color;
	text-align: center;
	&.link-green {
		color: @green-link-color;
	}
	&:hover,
	&:focus {
		color: @link-hover-color;
		text-decoration: underline;
	}

	&:focus {
		.tab-focus();
	}
	
}


// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
	margin: 0;
}


// Images

img {
	vertical-align: middle;
}

// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
	.img-responsive();
}

// Rounded corners
.img-rounded {
	border-radius: @border-radius-large;
}

// Image thumbnails
//
// Heads up! This is mixin-ed into thumbnails.less for `.thumbnail`.
.img-thumbnail {
	padding: @thumbnail-padding;
	line-height: @line-height-base;
	background-color: @thumbnail-bg;
	border: 1px solid @thumbnail-border;
	border-radius: @thumbnail-border-radius;
	.transition(all .2s ease-in-out);

	// Keep them at most 100% wide
	.img-responsive(inline-block);
}

// Perfect circle
.img-circle {
	border-radius: 50%; // set radius in percents
}


// Horizontal rules

hr {
	margin-top:    @line-height-computed;
	margin-bottom: @line-height-computed;
	border: 0;
	border-top: 1px solid @hr-border;
}


// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.tab-btn {
	a {
		text-decoration: none;
	}
	
}

.rel {
	position: relative;
}

.abs {
position: absolute;
}

.__bg-white {
	background: #FFF;
}

.margined-20 {
	margin-bottom: 20px !important;
}

.clearfix {
	clear: both;
}