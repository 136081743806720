//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @border-radius-base);
  .user-select(none);

  &,
  &:active,
  &.active {
    &:focus {
      .tab-focus();
    }
  }

  &:hover,
  &:focus {
    color: @btn-green-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    .opacity(.65);
    .box-shadow(none);
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-green {
  .button-variant(@btn-green-color; @btn-green-bg; @btn-green-border);
  #gradient > .vertical(#b4d73a,#88c124);
  &:active {
    #gradient > .vertical(#88c124,#b4d73a);
  }
  border-radius: @border-radius-none;
  text-shadow: 1px 1px 1px #bbde47;
  -webkit-box-shadow: inset 0px 0px 1px #a4ce32;
          box-shadow: inset 0px 0px 1px #a4ce32;
  padding: 3px 12px;
}
.btn-green-white {
  .button-variant(@btn-green-white-color; @btn-green-white-bg; @btn-green-white-border);
  #gradient > .vertical(#9dc925,#6da708);
  &:active {
    #gradient > .vertical(#6da708,#9dc925);
  }
  border-radius: 2px 2px 2px 2px;
  -webkit-box-shadow: inset 0px 0px 1px #abdb18;
          box-shadow: inset 0px 0px 1px #abdb18;
  text-shadow: 1px 1px 1px #5b8909;
  padding: 5px 12px;
}
// Grey
.btn-grey {
  .button-variant(@btn-grey-color; @btn-grey-bg; @btn-grey-border);
  #gradient > .vertical(#fdfdfd,#d8d8d8);
  &:active {
    #gradient > .vertical(#d8d8d8,#fdfdfd);
  }
  border-radius: @border-radius-none;
  padding: 3px 12px;
  text-shadow: 1px 1px 1px #fff;
}
// Info appears as blue-green
.btn-orange {
  .button-variant(@btn-orange-color; @btn-orange-bg; @btn-orange-border);
  #gradient > .vertical(#ffa600,#ff4e00);
  &:active {
    #gradient > .vertical(#ff4e00,#ffa600);
  }
}
.btn-sandy {
  .button-variant(@btn-sandy-color; @btn-sandy-bg; @btn-sandy-border);
  #gradient > .vertical(#fedd5b,#fdd229);
  &:active {
    #gradient > .vertical(#fdd229,#fedd5b);
  }
}
// Warning appears as orange
.btn-warning {
  .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;
  font-weight: normal;
  cursor: pointer;
  border-radius: 0;

  &,
  &:active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: underline;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-base);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-small);
}
.btn-xs {
  .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @border-radius-small);
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

.button-in-search {
  top: 0;
  right: 0;
}