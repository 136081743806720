//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: @border-radius-base;

  > li {
    display: inline-block;
    font-size: 10px;
    
    + li:before {
      content: "\003E"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 3px;
      color: #f56704;
    }
    a {
      color: #989898;
      font-size: 10px;
    }
  }

  > .active {
    color: @breadcrumb-active-color;
  }
}
